
import { computed, defineComponent, ref } from 'vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import type { BreadcrumbsLink, FlagCountry } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import type { InboundCallType } from '@/definitions/history/inboundCalls/types'
import { useRoute } from 'vue-router'
import { formatDate } from '@/services/dateTimeService'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'
import TmListChipRecipient from '@/components/shared/TmListChipRecipient.vue'
import CallsDetailsMoreActions from '@/components/pages/history/details/CallsDetailsMoreActions.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'

export default defineComponent({
  components: {
    PageContent,
    TmListChipRecipient,
    TmCountry,
    CallsDetailsMoreActions,
    TmAutoSizePanel,
    TmAutoSizePanelItemButton,
    TmAutoSizePanelMobileActivator,
    DetailsTableItem,
    DetailsTable,
  },
  setup() {
    const route = useRoute()
    const breadcrumbs: BreadcrumbsLink[] = [
      {
        label: 'Inbound calls',
        name: 'base.history.inboundCalls',
      },
      {
        label: '62471259',
      },
    ]
    const country: FlagCountry = {
      name: 'United States',
      id: 'us',
    }
    const tableItems = ref<InboundCallType[]>(getTableData('inboundCalls'))
    const activeItem = computed(() => tableItems.value.find((el) => el.id === route.params.id))
    const recipient = computed(() => {
      if (activeItem.value) {
        if (activeItem.value.from.name) {
          const [firstName, lastName] = activeItem.value.from.name?.split(' ')
          return {
            type: 'contact',
            firstName,
            lastName,
            avatarColor: activeItem.value.from.avatarColor,
          }
        }
        return {
          type: 'recipient',
          country: country,
          phone: activeItem.value.from.phone,
        }
      }

      return {}
    })

    return {
      formatDate,
      breadcrumbs,
      country,
      activeItem,
      recipient,
    }
  },
})
